<template>
	<ChatInterface />
</template>

<script>
import ChatInterface from './components/ChatInterface.vue';

export default {
	name: 'App',
	components: {
		ChatInterface
	}
}
</script>